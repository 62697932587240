import { defineStore } from 'pinia';
import { API } from 'shared/api';
import { capitalizeFirstLetter } from 'shared/utils';
export const getUsers = async (nickname) => {
    const users = await API.pending.searchUsers(nickname);
    return users ?? [];
};
export const getPlaces = async (userId) => {
    const places = await API.pending.getPlaces(userId);
    if (!places)
        return null;
    return places;
};
export const useTransferPurchaseStore = defineStore('transferPurchase', {
    state: () => ({
        robux: 0,
        nickname: '',
        userId: '',
        placeId: '',
        placeName: '',
        placeUid: '',
        step: 1,
        promoCode: {
            promo: '',
            bonus: 0,
            bonusForGamepass: 0,
        },
        rate: 0,
        rbxInStock: '0',
        modals: {
            isShowFeedback: false,
            isShowSocial: false,
        }
    }),
    getters: {
    // isAuthorized: (state) => state.authorized
    },
    actions: {
        setUserData(nickanme, userId) {
            this.nickname = nickanme;
            this.userId = userId;
        },
        setRobux(robux) {
            this.robux = robux;
        },
        setPlace(placeId, placeName, placeUid) {
            this.placeId = placeId;
            this.placeName = placeName;
            this.placeUid = placeUid;
        },
        setStep(step) {
            this.step = step;
        },
        setRate(rate) {
            this.rate = rate;
        },
        setRbxInStock(data) {
            this.rbxInStock = data;
        },
        setPromoCode(promo, bonus, bonusForGamepass) {
            this.promoCode.promo = promo;
            this.promoCode.bonus = bonus;
            this.promoCode.bonusForGamepass = bonusForGamepass;
        },
        resetPromoCode() {
            this.promoCode = {
                promo: '',
                bonus: 0,
                bonusForGamepass: 0,
            };
        },
        resetCacheShowModalOnce(modal) {
            if (!localStorage.getItem(`${modal}ModalIsReset_2`)) {
                localStorage.removeItem(`${modal}ModalShown`);
                localStorage.setItem(`${modal}ModalIsReset_2`, '1');
            }
        },
        showModal(modal) {
            if (!localStorage.getItem(`${modal}ModalShown`)) {
                const capitalizedModal = capitalizeFirstLetter(modal);
                this.modals[`isShow${capitalizedModal}`] = true;
            }
            localStorage.setItem(`${modal}ModalShown`, '1');
        },
        hideModal(modal) {
            const capitalizedModal = capitalizeFirstLetter(modal);
            this.modals[`isShow${capitalizedModal}`] = false;
        },
    }
});
